
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { NumbersMock, SendersIdMock } from '@/definitions/services/byoc/data'
import SenderIdTable from '@/components/pages/chats/settings/byoc/senderId/SenderIdTable.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    SenderIdTable,
    LinkNumbersTable,
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { openModal } = useModals()
    const tableSenderHeaders = ref<TableHeaders[]>([
      { text: 'Sender ID', value: 'sender-id-slot' },
      { text: 'Calls', value: 'voice-slot', width: '10%' },
      { text: 'SMS', value: 'sms-slot', width: '10%' },
      { text: 'MMS', value: 'mms-slot', width: '20%' },
      { text: 'Provider', value: 'provider-slot' },
    ])
    const tableSenderItems = ref(SendersIdMock)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label in Textmagic', value: 'label-slot' },
      { text: 'Calls', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Forward calls to', value: 'forward-slot' },
    ])
    const tableItems = ref(NumbersMock)

    return {
      tableSenderHeaders,
      tableSenderItems,
      tableItems,
      tableHeaders,
      openModal,
    }
  },
})
