
import { defineComponent } from 'vue'
import ByocDetailsPage from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsPage.vue'
import ByocDetailsTwilioGeneral from '@/components/pages/chats/settings/byoc/byocDetails/twilio/ByocDetailsTwilioGeneral.vue'
import ByocDetailsTwilioLinkedNumbers from '@/components/pages/chats/settings/byoc/byocDetails/twilio/ByocDetailsTwilioLinkedNumbers.vue'
import { providerTwilio } from '@/definitions/chats/settings/byoc/data'

export default defineComponent({
  components: {
    ByocDetailsPage,
    ByocDetailsTwilioGeneral,
    ByocDetailsTwilioLinkedNumbers,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Bring your own CPaaS', url: { name: 'base.chats.settings.byoc' } },
      { label: 'Twilio Production' },
    ]

    return {
      breadcrumbs,
      provider: providerTwilio,
    }
  },
})
